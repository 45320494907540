<script>
import { authComputed } from '@state/helpers'
import MetisMenu from 'metismenujs/dist/metismenujs'

export default {
    components: {},
    mixins: [],
    computed: {
        ...authComputed,
        posLabel() {
            return this.getI18nModified({
                suffix: 'TITLES.pos',
                prefix: 'POS',
                modifier: 2,
            })
        },
        posChannelLabel() {
            return this.getI18nModified({
                suffix: 'TITLES.channel',
                prefix: 'POS',
                modifier: 2,
            })
        },
        posCategoryLabel() {
            return this.getI18nModified({
                suffix: 'TITLES.category',
                prefix: 'POS',
                modifier: 2,
            })
        },
        posChainLabel() {
            return this.getI18nModified({
                suffix: 'TITLES.chain',
                prefix: 'POS',
                modifier: 2,
            })
        },
        posRegionalLabel() {
            return this.getI18nModified({
                suffix: 'TITLES.regional',
                prefix: 'POS',
                modifier: 2,
            })
        },
        posFlagLabel() {
            return this.getI18nModified({
                suffix: 'TITLES.flag',
                prefix: 'POS',
                modifier: 2,
            })
        },

        businessUnitsLabel() {
            return this.getI18nModified({
                suffix: 'TITLES.business_unit',
                prefix: 'BUSINESS_UNITS',
                modifier: 2,
            })
        },
        businessUnitCheckLabel() {
            return this.getI18nModified({
                suffix: 'TITLES.buscheck',
                prefix: 'BUSINESS_UNITS_CHECK',
                modifier: 2,
            })
        },
        collaboratorsLabel() {
            return this.getI18nModified({
                suffix: 'TITLES.collaborator',
                prefix: 'USERS',
                modifier: 2,
            })
        },
        usersLabel() {
            return this.getI18nModified({
                suffix: 'TITLES.user',
                prefix: 'USERS',
                modifier: 2,
            })
        },
        profileLabel() {
            return this.getI18nModified({
                suffix: 'TITLES.profile',
                prefix: 'PROFILE',
                modifier: 2,
            })
        },
        rolesLabel() {
            return this.getI18nModified({
                suffix: 'TITLES.role',
                prefix: 'SECURITY',
                modifier: 2,
            })
        },
        securityLabel() {
            return this.getI18nModified({
                suffix: 'TITLES.security',
                prefix: 'SECURITY',
                modifier: 2,
            })
        },
        tenantLabel() {
            return this.getI18nModified({
                prefix: 'TENANT',
                suffix: 'TITLES.tenant',
                modifier: 2,
            })
        },
        productCategoriesLabel() {
            return this.getI18nModified({
                suffix: 'TITLES.product_category',
                prefix: 'PRODUCT_CATEGORIES',
                modifier: 2,
            })
        },
        productsLabel() {
            return this.getI18nModified({
                suffix: 'TITLES.product',
                prefix: 'PRODUCTS',
                modifier: 2,
            })
        },
        questionnairesLabel() {
            return this.getI18nModified({
                suffix: 'TITLES.questionnaire',
                prefix: 'QUESTIONNAIRES',
                modifier: 2,
            })
        },
        questionnaireAnswersLabel() {
            return this.getI18nModified({
                suffix: 'TITLES.questionnaire_answer',
                prefix: 'QUESTIONNAIRE_ANSWERS',
                modifier: 2,
            })
        },
        workdayBreakLabel() {
            return this.getI18nModified({
                suffix: 'TITLES.workday_break',
                prefix: 'WORKDAYS_BREAKS',
                modifier: 2,
            })
        },
        pointLabel() {
            return this.getI18nModified({
                suffix: 'point',
                prefix: 'COMMON',
                modifier: 2,
            })
        },
        exportJobsLabel() {
            return this.getI18nModified({
                suffix: 'TITLES.export',
                prefix: 'EXPORT_JOBS',
                modifier: 2,
            })
        },
        resumeUserLabel() {
            return this.getI18nModified({
                suffix: 'TITLES.resume_user',
                prefix: 'RESUME_USER',
                modifier: 2,
            })
        },
        reportsLabel() {
            return this.getI18nModified({
                suffix: 'report',
                prefix: 'COMMON',
                modifier: 2,
            })
        },
        questionsLabel() {
            return this.getI18nModified({
                suffix: 'TITLES.question',
                prefix: 'QUESTIONS',
                modifier: 2,
            })
        },
        mixLabel() {
            return this.getI18nModified({
                suffix: 'TITLES.mix',
                prefix: 'MIXES',
                modifier: 2,
            })
        },
        justificationLabel() {
            return this.getI18nModified({
                suffix: 'TITLES.justification',
                prefix: 'JUSTIFICATION',
                modifier: 2,
            })
        },
        justificationTypeLabel() {
            return this.getI18nModified({
                suffix: 'TITLES.justification_type',
                prefix: 'JUSTIFICATION',
                modifier: 2,
            })
        },
        teamsLabel() {
            return this.getI18nModified({
                suffix: 'TITLES.team',
                prefix: 'TEAMS',
                modifier: 2,
            })
        },
        workScheduleLabel() {
            return this.getI18nModified({
                suffix: 'TITLES.work_schedule',
                prefix: 'WORKSCHEDULES',
                modifier: 2,
            })
        },
        workdaysLabel() {
            return this.getI18nModified({
                suffix: 'TITLES.workday',
                prefix: 'WORKDAYS',
                modifier: 2,
            })
        },
        workLeavesLabel() {
            return this.getI18nModified({
                suffix: 'TITLES.work_leave',
                prefix: 'WORK_LEAVES',
                modifier: 2,
            })
        },
        filesLabel() {
            return this.getI18nModified({
                suffix: 'TITLES.file',
                prefix: 'FILES',
                modifier: 2,
            })
        },
        posCheckLabel() {
            return this.getI18nModified({
                suffix: 'TITLES.poscheck_summary',
                prefix: 'POS_CHECK',
                modifier: 1,
            })
        },
        brandLabel() {
            return this.getI18nModified({
                suffix: 'TITLES.brand',
                prefix: 'BRANDS',
                modifier: 2,
            })
        },
        importJobLabel() {
            return this.getI18nModified({
                suffix: 'TITLES.import_job',
                prefix: 'IMPORT_JOBS',
                modifier: 2,
            })
        },
        exportPresentationJobLabel() {
            return this.getI18nModified({
                suffix: 'TITLES.export_presentation_job',
                prefix: 'EXPORT_PRESENTATION_JOBS',
                modifier: 1,
            })
        },
        operationalProblem() {
            return this.getI18nModified({
                suffix: 'TITLES.operational_problem',
                prefix: 'OPERATIONAL_PROBLEM',
                modifier: 2,
            })
        },
        toolsLabel() {
            return this.getI18nModified({
                suffix: 'tool',
                prefix: 'COMMON',
                modifier: 2,
            })
        },
        dashboardsLabel() {
            return this.getI18nModified({
                suffix: 'TITLES.dashboards',
                prefix: 'DASHBOARDS',
                modifier: 2,
            })
        },
        resumeLabel() {
            return this.getI18nModified({
                suffix: 'TITLES.resume',
                prefix: 'DASHBOARDS',
                modifier: 2,
            })
        },
        geopositioningLabel() {
            return this.getI18nModified({
                prefix: 'COMMON',
                suffix: 'geopositioning',
                modifier: 2,
            })
        },
        getTrackingUser() {
            return this.getI18nModified({
                prefix: 'COMMON',
                suffix: 'trackUser',
                modifier: 2,
            })
        },
        dailyRouteLabel() {
            return this.getI18nModified({
                prefix: 'COMMON',
                suffix: 'daily_route',
                modifier: 2,
            })
        },
        operationalLabel() {
            return this.getI18nModified({
                suffix: 'TITLES.operational',
                prefix: 'DASHBOARDS',
                modifier: 2,
            })
        },
        dashboardPriceLabel() {
            return this.getI18nModified({
                suffix: 'TITLES.price',
                prefix: 'DASHBOARDS',
                modifier: 1,
            })
        },
        dashboardShareLabel() {
            return this.getI18nModified({
                suffix: 'TITLES.share',
                prefix: 'DASHBOARDS',
                modifier: 1,
            })
        },
        dashboardDueDateLabel() {
            return this.getI18nModified({
                suffix: 'TITLES.due_date',
                prefix: 'DASHBOARDS',
                modifier: 1,
            })
        },
        dashboardOutOfStockLabel() {
            return this.getI18nModified({
                suffix: 'TITLES.out_of_stock',
                prefix: 'DASHBOARDS',
                modifier: 1,
            })
        },
        dashboardAdditionalPointLabel() {
            return this.getI18nModified({
                suffix: 'TITLES.additional_point',
                prefix: 'DASHBOARDS',
                modifier: 1,
            })
        },
        dashboardPhotoGalleryLabel() {
            return this.getI18nModified({
                suffix: 'TITLES.photo_gallery',
                prefix: 'DASHBOARDS',
                modifier: 1,
            })
        },
        dashboardUserPerformanceLabel() {
            return this.getI18nModified({
                suffix: 'TITLES.performance_user',
                prefix: 'DASHBOARDS',
                modifier: 1,
            })
        },
        dashboardPosPerformanceLabel() {
            return this.getI18nModified({
                suffix: 'TITLES.performance_pos',
                prefix: 'DASHBOARDS',
                modifier: 1,
            })
        },
        intelligenceLabel() {
            return this.getI18nModified({
                suffix: 'intelligence',
                prefix: 'COMMON',
                modifier: 1,
            })
        },
        costOfServingLabel() {
            return this.getI18nModified({
                suffix: 'TITLES.cost_of_serving',
                prefix: 'DASHBOARDS',
                modifier: 2,
            })
        },
        commercialLabel() {
            return this.getI18nModified({
                suffix: 'commercial',
                prefix: 'COMMON',
                modifier: 1,
            })
        },
        orderHistoryLabel() {
            return this.getI18nModified({
                suffix: 'TITLES.order_history',
                prefix: 'DASHBOARDS',
                modifier: 2,
            })
        },
        costOfServingModuleEnable() {
            const { costOfServingModule } = this.$store.getters['auth/activeModules']
            return costOfServingModule
        },
        commercialModuleEnable() {
            const { commercialModule } = this.$store.getters['auth/activeModules']
            return commercialModule
        }
    },
    mounted: function () {
        // eslint-disable-next-line no-unused-vars
        var menuRef = new MetisMenu('#side-menu')
        var links = document.getElementsByClassName('side-nav-link-ref')
        var matchingMenuItem = null
        for (var i = 0; i < links.length; i++) {
            if (window.location.pathname === links[i].pathname) {
                matchingMenuItem = links[i]
                break
            }
        }

        if (matchingMenuItem) {
            matchingMenuItem.classList.add('active')
            var parent = matchingMenuItem.parentElement

            /**
             * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
             * We should come up with non hard coded approach
             */
            if (parent) {
                parent.classList.add('active')
                const parent2 = parent.parentElement
                if (parent2) {
                    parent2.classList.add('in')
                }
                const parent3 = parent2.parentElement
                if (parent3) {
                    parent3.classList.add('active')
                    var childAnchor = parent3.querySelector('.has-dropdown')
                    if (childAnchor) childAnchor.classList.add('active')
                }

                const parent4 = parent3.parentElement
                if (parent4) parent4.classList.add('in')
                const parent5 = parent4.parentElement
                if (parent5) parent5.classList.add('active')
            }
        }
    },
    methods: {
        /**
         * small sidebar
         */
        smallSidebar() {
            document.body.classList.add('left-side-menu-sm')
            document.body.classList.remove('left-side-menu-dark')
            document.body.classList.remove('topbar-light')
            document.body.classList.remove('boxed-layout')
            document.body.classList.remove('enlarged')
        },

        /**
         * Dark sidebar
         */
        darkSidebar() {
            document.body.classList.remove('left-side-menu-sm')
            document.body.classList.add('left-side-menu-dark')
            document.body.classList.remove('topbar-light')
            document.body.classList.remove('boxed-layout')
        },

        /**
         * Light Topbar
         */
        lightTopbar() {
            document.body.classList.add('topbar-light')
            document.body.classList.remove('left-side-menu-dark')
            document.body.classList.remove('left-side-menu-sm')
            document.body.classList.remove('boxed-layout')
        },

        /**
         * Sidebar collapsed
         */
        sidebarCollapsed() {
            document.body.classList.remove('left-side-menu-dark')
            document.body.classList.remove('left-side-menu-sm')
            document.body.classList.toggle('enlarged')
            document.body.classList.remove('boxed-layout')
            document.body.classList.remove('topbar-light')
        },

        /**
         * Boxed Layout
         */
        boxedLayout() {
            document.body.classList.add('boxed-layout')
            document.body.classList.remove('left-side-menu-dark')
            document.body.classList.add('enlarged')
            document.body.classList.remove('left-side-menu-sm')
        },
    },
}
</script>

<template>
    <!--- Sidemenu -->
    <div id="sidebar-menu">
        <ul id="side-menu" class="metismenu">
            <!-- Dashboards -->
            <li
                v-if="
                    $can('GET', 'DASHBOARDRESUME') ||
                    $can('GET', 'DASHBOARDGEOLOCATION') ||
                    $can('GET', 'DASHBOARDDAILYROUTE') ||
                    $can('GET', 'PHOTOGALLERYESCREEN') ||
                    $can('GET', 'USERPERFORMANCESCREEN') ||
                    $can('GET', 'POSPERFORMANCESCREEN') ||
                    $can('GET', 'DASHBOARDOPERATIONAL') ||
                    $can('GET', 'DASHBOARDPRICE') ||
                    $can('GET', 'DASHBOARDOUTOFSTOCK') ||
                    $can('GET', 'DASHBOARDOUTADDITIONALPOINT') ||
                    $can('GET', 'DASHBOARDDUEDATE')
                "
            >
                <a
                    href="javascript: void(0);"
                    aria-expanded="false"
                    class="has-dropdown"
                >
                    <i class="fe-layout"></i>
                    <span>{{ dashboardsLabel }}</span>
                    <span class="menu-arrow"></span>
                </a>
                <ul class="nav-second-level" aria-expanded="false">
                    <li v-if="$can('GET', 'DASHBOARDRESUME')">
                        <router-link v-slot="{ navigate }" to="/" custom
                            ><a
                                class="side-nav-link-ref"
                                role="link"
                                @click="navigate"
                                >{{ resumeLabel }}</a
                            >
                        </router-link>
                    </li>
                    <li v-if="$can('GET', 'DASHBOARDGEOLOCATION')">
                        <router-link
                            v-slot="{ navigate }"
                            to="/dashboard/trackMap"
                            custom
                            ><a
                                class="side-nav-link-ref"
                                role="link"
                                @click="navigate"
                                >{{ geopositioningLabel }}</a
                            >
                        </router-link>
                    </li>
                    <li v-if="$can('GET', 'DASHBOARDGEOLOCATION')">
                        <router-link
                            v-slot="{ navigate }"
                            to="/dashboard/trackUser"
                            custom
                            ><a
                                class="side-nav-link-ref"
                                role="link"
                                @click="navigate"
                                >{{ getTrackingUser }}</a
                            >
                        </router-link>
                    </li>
                    <li v-if="$can('GET', 'DASHBOARDDAILYROUTE')">
                        <router-link
                            v-slot="{ navigate }"
                            to="/dashboard/workSchedulesMap"
                            custom
                            ><a
                                class="side-nav-link-ref"
                                role="link"
                                @click="navigate"
                                >{{ dailyRouteLabel }}</a
                            >
                        </router-link>
                    </li>
                    <li v-if="$can('GET', 'PHOTOGALLERYESCREEN')">
                        <router-link
                            v-slot="{ navigate }"
                            to="/dashboard/photoGallery"
                            custom
                            ><a
                                class="side-nav-link-ref"
                                role="link"
                                @click="navigate"
                                >{{ dashboardPhotoGalleryLabel }}</a
                            >
                        </router-link>
                    </li>
                    <li v-if="$can('GET', 'USERPERFORMANCESCREEN')">
                        <router-link
                            v-slot="{ navigate }"
                            to="/dashboard/performance/user"
                            custom
                            ><a
                                class="side-nav-link-ref"
                                role="link"
                                @click="navigate"
                                >{{ dashboardUserPerformanceLabel }}</a
                            >
                        </router-link>
                    </li>
                    <li v-if="$can('GET', 'POSPERFORMANCESCREEN')">
                        <router-link
                            v-slot="{ navigate }"
                            to="/dashboard/performance/pos"
                            custom
                            ><a
                                class="side-nav-link-ref"
                                role="link"
                                @click="navigate"
                                >{{ dashboardPosPerformanceLabel }}</a
                            >
                        </router-link>
                    </li>
                    <li v-if="$can('GET', 'DASHBOARDOPERATIONAL')">
                        <router-link
                            v-slot="{ navigate }"
                            to="/dashboard/operational"
                            custom
                            ><a
                                class="side-nav-link-ref"
                                role="link"
                                @click="navigate"
                                >{{ operationalLabel }}</a
                            >
                        </router-link>
                    </li>
                    <li v-if="$can('GET', 'DASHBOARDPRICE')">
                        <router-link
                            v-slot="{ navigate }"
                            to="/dashboard/price"
                            custom
                            ><a
                                class="side-nav-link-ref"
                                role="link"
                                @click="navigate"
                                >{{ dashboardPriceLabel }}</a
                            >
                        </router-link>
                    </li>
                    <li v-if="$can('GET', 'DASHBOARDSHARE')">
                        <router-link
                            v-slot="{ navigate }"
                            to="/dashboard/share"
                            custom
                            ><a
                                class="side-nav-link-ref"
                                role="link"
                                @click="navigate"
                                >{{ dashboardShareLabel }}</a
                            >
                        </router-link>
                    </li>
                    <li v-if="$can('GET', 'DASHBOARDDUEDATE')">
                        <router-link
                            v-slot="{ navigate }"
                            to="/dashboard/dueDate"
                            custom
                            ><a
                                class="side-nav-link-ref"
                                role="link"
                                @click="navigate"
                                >{{ dashboardDueDateLabel }}</a
                            >
                        </router-link>
                    </li>
                    <li v-if="$can('GET', 'DASHBOARDOUTOFSTOCK')">
                        <router-link
                            v-slot="{ navigate }"
                            to="/dashboard/outOfStock"
                            custom
                            ><a
                                class="side-nav-link-ref"
                                role="link"
                                @click="navigate"
                                >{{ dashboardOutOfStockLabel }}</a
                            >
                        </router-link>
                    </li>
                    <li v-if="$can('GET', 'DASHBOARDOUTADDITIONALPOINT')">
                        <router-link
                            v-slot="{ navigate }"
                            to="/dashboard/additionalPoint"
                            custom
                            ><a
                                class="side-nav-link-ref"
                                role="link"
                                @click="navigate"
                                >{{ dashboardAdditionalPointLabel }}</a
                            >
                        </router-link>
                    </li>
                </ul>
            </li>
            <!-- Comercial -->
            <li v-if="commercialModuleEnable">
                <a
                    href="javascript: void(0);"
                    aria-expanded="false"
                    class="has-dropdown"
                >
                    <i class="fe-layers"></i>
                    <span>{{ commercialLabel }}</span>
                    <span class="menu-arrow"></span>
                </a>
                <ul class="nav-second-level" aria-expanded="false">
                    <li>
                        <router-link
                            v-slot="{ navigate }"
                            to="/commercial/orderHistory"
                            custom
                            ><a
                                class="side-nav-link-ref"
                                role="link"
                                @click="navigate"
                                >{{ orderHistoryLabel }}</a
                            >
                        </router-link>
                    </li>
                </ul>
            </li>
            <!-- Inteligência -->
            <li v-if="costOfServingModuleEnable">
                <a
                    href="javascript: void(0);"
                    aria-expanded="false"
                    class="has-dropdown"
                >
                    <i class="fe-grid"></i>
                    <span>{{ intelligenceLabel }}</span>
                    <span class="menu-arrow"></span>
                </a>
                <ul class="nav-second-level" aria-expanded="false">
                    <li>
                        <router-link
                            v-slot="{ navigate }"
                            to="/intelligence/costOfServing"
                            custom
                            ><a
                                class="side-nav-link-ref"
                                role="link"
                                @click="navigate"
                                >{{ costOfServingLabel }}</a
                            >
                        </router-link>
                    </li>
                </ul>
            </li>
            <!-- Ponto de Venda -->
            <li
                v-if="
                    $can('GET', 'POSSCREEN') ||
                    $can('GET', 'CHANNELSSCREEN') ||
                    $can('GET', 'CHAINSSCREEN') ||
                    $can('GET', 'REGIONALSSCREEN') ||
                    $can('GET', 'FLAGSSCREEN') ||
                    $can('GET', 'CATEGORIESSCREEN') ||
                    $can('GET', 'CHECKSSCREEN') ||
                    $can('GET', 'BUSINESSUNITSSCREEN') ||
                    costOfServingModuleEnable
                "
            >
                <a
                    href="javascript: void(0);"
                    aria-expanded="false"
                    class="has-dropdown"
                >
                    <i class="fe-shopping-cart"></i>
                    <span>{{ posLabel }}</span>
                    <span class="menu-arrow"></span>
                </a>
                <ul class="nav-second-level" aria-expanded="false">
                    <li v-if="$can('GET', 'POSSCREEN')">
                        <router-link v-slot="{ navigate }" to="/pos" custom
                            ><a
                                class="side-nav-link-ref"
                                role="link"
                                @click="navigate"
                                >{{ posLabel }}</a
                            >
                        </router-link>
                    </li>
                    <li v-if="$can('GET', 'BUSINESSUNITSSCREEN')">
                        <router-link
                            v-slot="{ navigate }"
                            to="/business-units"
                            custom
                            ><a
                                class="side-nav-link-ref"
                                role="link"
                                @click="navigate"
                                >{{ businessUnitsLabel }}</a
                            >
                        </router-link>
                    </li>
                    <li v-if="$can('GET', 'CHANNELSSCREEN')">
                        <router-link
                            v-slot="{ navigate }"
                            to="/posChannel"
                            custom
                            ><a
                                class="side-nav-link-ref"
                                role="link"
                                @click="navigate"
                                >{{ posChannelLabel }}</a
                            >
                        </router-link>
                    </li>
                    <li v-if="$can('GET', 'CATEGORIESSCREEN')">
                        <router-link
                            v-slot="{ navigate }"
                            to="/posCategory"
                            custom
                            ><a
                                class="side-nav-link-ref"
                                role="link"
                                @click="navigate"
                                >{{ posCategoryLabel }}</a
                            >
                        </router-link>
                    </li>
                    <li v-if="$can('GET', 'CHAINSSCREEN')">
                        <router-link v-slot="{ navigate }" to="/posChain" custom
                            ><a
                                class="side-nav-link-ref"
                                role="link"
                                @click="navigate"
                                >{{ posChainLabel }}</a
                            >
                        </router-link>
                    </li>
                    <li v-if="$can('GET', 'REGIONALSSCREEN')">
                        <router-link
                            v-slot="{ navigate }"
                            to="/posRegional"
                            custom
                            ><a
                                class="side-nav-link-ref"
                                role="link"
                                @click="navigate"
                                >{{ posRegionalLabel }}</a
                            >
                        </router-link>
                    </li>
                    <li v-if="$can('GET', 'FLAGSSCREEN')">
                        <router-link v-slot="{ navigate }" to="/posFlag" custom
                            ><a
                                class="side-nav-link-ref"
                                role="link"
                                @click="navigate"
                                >{{ posFlagLabel }}</a
                            >
                        </router-link>
                    </li>
                    <li v-if="$can('GET', 'CHECKSSCREEN')">
                        <router-link v-slot="{ navigate }" to="/posCheck" custom
                            ><a
                                class="side-nav-link-ref"
                                role="link"
                                @click="navigate"
                                >{{ posCheckLabel }}</a
                            >
                        </router-link>
                    </li>
                    <li v-if="costOfServingModuleEnable">
                        <router-link
                            v-slot="{ navigate }"
                            to="/posIntelligence"
                            custom
                            ><a
                                class="side-nav-link-ref"
                                role="link"
                                @click="navigate"
                                >{{ posLabel }}</a
                            >
                        </router-link>
                    </li>
                    <li v-if="costOfServingModuleEnable">
                        <router-link
                            v-slot="{ navigate }"
                            to="/businessUnitsIntelligence"
                            custom
                            ><a
                                class="side-nav-link-ref"
                                role="link"
                                @click="navigate"
                                >{{ businessUnitsLabel }}</a
                            >
                        </router-link>
                    </li>
                    <li v-if="costOfServingModuleEnable">
                        <router-link
                            v-slot="{ navigate }"
                            to="/posChainIntelligence"
                            custom
                            ><a
                                class="side-nav-link-ref"
                                role="link"
                                @click="navigate"
                                >{{ posChainLabel }}</a
                            >
                        </router-link>
                    </li>
                </ul>
            </li>
            <!-- Colaboradores -->
            <li
                v-if="
                    $can('GET', 'USERSSCREEN') ||
                    $can('GET', 'TEAMSSCREEN') ||
                    $can('GET', 'WORKSDAYSSSCREEN') ||
                    $can('GET', 'WORKLEAVESSCREEN') ||
                    (isAdmin && !isSuperAdmin)
                "
            >
                <a
                    href="javascript: void(0);"
                    aria-expanded="false"
                    class="has-dropdown"
                >
                    <i class="fe-user"></i>
                    <span>{{ collaboratorsLabel }}</span>
                    <span class="menu-arrow"></span>
                </a>
                <ul class="nav-second-level" aria-expanded="false">
                    <li v-if="$can('GET', 'USERSSCREEN')">
                        <router-link v-slot="{ navigate }" to="/users" custom
                            ><a
                                class="side-nav-link-ref"
                                role="link"
                                @click="navigate"
                                >{{ usersLabel }}</a
                            >
                        </router-link>
                    </li>
                    <li v-if="isAdmin">
                        <router-link v-slot="{ navigate }" to="/roles" custom
                            ><a
                                class="side-nav-link-ref"
                                role="link"
                                @click="navigate"
                                >{{ rolesLabel }}</a
                            >
                        </router-link>
                    </li>
                    <li v-if="$can('GET', 'TEAMSSCREEN')">
                        <router-link v-slot="{ navigate }" to="/teams" custom
                            ><a
                                class="side-nav-link-ref"
                                role="link"
                                @click="navigate"
                                >{{ teamsLabel }}</a
                            >
                        </router-link>
                    </li>
                    <li v-if="$can('GET', 'WORKSDAYSSSCREEN')">
                        <router-link v-slot="{ navigate }" to="/workdays" custom
                            ><a
                                class="side-nav-link-ref"
                                role="link"
                                @click="navigate"
                                >{{ workdaysLabel }}</a
                            >
                        </router-link>
                    </li>
                    <li v-if="$can('GET', 'WORKLEAVESSCREEN')">
                        <router-link v-slot="{ navigate }" to="/workLeaves" custom
                            ><a
                                class="side-nav-link-ref"
                                role="link"
                                @click="navigate"
                                >{{ workLeavesLabel }}</a
                            >
                        </router-link>
                    </li>
                    <li v-if="$can('GET', 'ATTACHMENTSSCREEN')">
                        <router-link v-slot="{ navigate }" to="/files" custom
                            ><a
                                class="side-nav-link-ref"
                                role="link"
                                @click="navigate"
                                >{{ filesLabel }}</a
                            >
                        </router-link>
                    </li>
                </ul>
            </li>
            <!-- Entidades -->
            <li v-if="isSuperAdmin">
                <a
                    href="javascript: void(0);"
                    aria-expanded="false"
                    class="has-dropdown"
                >
                    <i class="fa fa-lock" aria-hidden="true"></i>
                    <span>{{ securityLabel }}</span>
                    <span class="menu-arrow"></span>
                </a>
                <ul class="nav-second-level" aria-expanded="false">
                    <li v-if="isSuperAdmin">
                        <router-link v-slot="{ navigate }" to="/tenant" custom
                            ><a
                                class="side-nav-link-ref"
                                role="link"
                                @click="navigate"
                                >{{ tenantLabel }}</a
                            >
                        </router-link>
                    </li>
                </ul>
            </li>
            <!-- Produtos -->
            <li
                v-if="
                    $can('GET', 'PRODUUCTSSCREEN') ||
                    $can('GET', 'PRODUUCTCATEGORIESSCREEN') ||
                    $can('GET', 'PRODUUCTBRANDSSCREEN') ||
                    $can('GET', 'MIXESSCREEN')
                "
            >
                <a
                    href="javascript: void(0);"
                    aria-expanded="false"
                    class="has-dropdown"
                >
                    <i class="fe-tag"></i>
                    <span>{{ productsLabel }}</span>
                    <span class="menu-arrow"></span>
                </a>
                <ul class="nav-second-level" aria-expanded="false">
                    <li v-if="$can('GET', 'MIXESSCREEN')">
                        <router-link v-slot="{ navigate }" to="/mixes" custom
                            ><a
                                class="side-nav-link-ref"
                                role="link"
                                @click="navigate"
                                >{{ mixLabel }}</a
                            >
                        </router-link>
                    </li>
                    <li v-if="$can('GET', 'PRODUUCTSSCREEN')">
                        <router-link v-slot="{ navigate }" to="/products" custom
                            ><a
                                class="side-nav-link-ref"
                                role="link"
                                @click="navigate"
                                >{{ productsLabel }}</a
                            >
                        </router-link>
                    </li>
                    <li v-if="$can('GET', 'PRODUUCTCATEGORIESSCREEN')">
                        <router-link
                            v-slot="{ navigate }"
                            to="/product-categories"
                            custom
                            ><a
                                class="side-nav-link-ref"
                                role="link"
                                @click="navigate"
                                >{{ productCategoriesLabel }}</a
                            >
                        </router-link>
                    </li>
                    <li v-if="$can('GET', 'PRODUUCTBRANDSSCREEN')">
                        <router-link v-slot="{ navigate }" to="/brands" custom
                            ><a
                                class="side-nav-link-ref"
                                role="link"
                                @click="navigate"
                                >{{ brandLabel }}</a
                            >
                        </router-link>
                    </li>
                </ul>
            </li>
            <!-- Justificativas -->
            <li v-if="$can('GET', 'JUSTIFICATIONSSCREEN')">
                <a
                    href="javascript: void(0);"
                    aria-expanded="false"
                    class="has-dropdown"
                >
                    <i class="fe-message-square" aria-hidden="true"></i>
                    <span>{{ justificationLabel }}</span>
                    <span class="menu-arrow"></span>
                </a>
                <ul class="nav-second-level" aria-expanded="false">
                    <li v-if="$can('GET', 'JUSTIFICATIONSSCREEN')">
                        <router-link
                            v-slot="{ navigate }"
                            to="/justifications"
                            custom
                            ><a
                                class="side-nav-link-ref"
                                role="link"
                                @click="navigate"
                                >{{ justificationLabel }}</a
                            >
                        </router-link>
                    </li>
                    <li v-if="$can('GET', 'JUSTIFICATIONTYPESSCREEN')">
                        <router-link
                            v-slot="{ navigate }"
                            to="/justifications-types"
                            custom
                            ><a
                                class="side-nav-link-ref"
                                role="link"
                                @click="navigate"
                                >{{ justificationTypeLabel }}</a
                            >
                        </router-link>
                    </li>
                </ul>
            </li>
            <!-- Roteiros -->
            <li
                v-if="
                    $can('GET', 'WORKSCHEDULESSCREEN') ||
                    $can('GET', 'QUESTIONNAIRESSCREEN') ||
                    $can('GET', 'QUESTIONSSCREEN')
                "
            >
                <a
                    href="javascript: void(0);"
                    aria-expanded="false"
                    class="has-dropdown"
                >
                    <i
                        class="fe-map"
                        aria-hidden="true"
                    ></i>
                    <span>{{ workScheduleLabel }}</span>
                    <span class="menu-arrow"></span>
                </a>
                <ul class="nav-second-level" aria-expanded="false">
                    <li v-if="$can('GET', 'WORKSCHEDULESSCREEN')">
                        <router-link
                            v-slot="{ navigate }"
                            to="/workschedules"
                            custom
                            ><a
                                class="side-nav-link-ref"
                                role="link"
                                @click="navigate"
                                >{{ workScheduleLabel }}</a
                            >
                        </router-link>
                    </li>
                    <li v-if="$can('GET', 'QUESTIONNAIRESSCREEN')">
                        <router-link
                            v-slot="{ navigate }"
                            to="/questionnaires"
                            custom
                            ><a
                                class="side-nav-link-ref"
                                role="link"
                                @click="navigate"
                                >{{ questionnairesLabel }}</a
                            >
                        </router-link>
                    </li>
                    <li v-if="$can('GET', 'QUESTIONSSCREEN')">
                        <router-link
                            v-slot="{ navigate }"
                            to="/questions"
                            custom
                            ><a
                                class="side-nav-link-ref"
                                role="link"
                                @click="navigate"
                                >{{ questionsLabel }}</a
                            >
                        </router-link>
                    </li>
                </ul>
            </li>
            <!-- Relatorios -->
            <li
                v-if="
                    $can('GET', 'AUDITSSCREEN') ||
                    $can('GET', 'AUDITSEXPORTSCREEN') ||
                    $can('GET', 'WORKDAYBREAKSSCREEN') ||
                    $can('GET', 'IMPORTSSCREEN') ||
                    $can('GET', 'EXPORTSSCREEN')
                "
            >
                <a
                    href="javascript: void(0);"
                    aria-expanded="false"
                    class="has-dropdown"
                >
                    <i class="fe-clipboard"></i>
                    <span>{{ reportsLabel }}</span>
                    <span class="menu-arrow"></span>
                </a>
                <ul class="nav-second-level" aria-expanded="false">
                    <li v-if="$can('GET', 'AUDITSSCREEN')">
                        <router-link
                            v-slot="{ navigate }"
                            to="/questionnaireAnswers"
                            custom
                            ><a
                                class="side-nav-link-ref"
                                role="link"
                                @click="navigate"
                                >{{ questionnaireAnswersLabel }}</a
                            >
                        </router-link>
                    </li>
                    <li v-if="$can('GET', 'WORKDAYBREAKSSCREEN')">
                        <router-link
                            v-slot="{ navigate }"
                            to="/reports/workdayBreaks"
                            custom
                            ><a
                                class="side-nav-link-ref"
                                role="link"
                                @click="navigate"
                                >{{ workdayBreakLabel }}</a
                            >
                        </router-link>
                    </li>
                    <li v-if="$can('GET', 'WORKDAYCHECKRESUMESCREEN')">
                        <router-link
                            v-slot="{ navigate }"
                            to="/reports/points"
                            custom
                            ><a
                                class="side-nav-link-ref"
                                role="link"
                                @click="navigate"
                                >{{ pointLabel }}</a
                            >
                        </router-link>
                    </li>
                    <li v-if="$can('GET', 'AUDITSEXPORTSCREEN')">
                        <router-link
                            v-slot="{ navigate }"
                            to="/resumeUser"
                            custom
                            ><a
                                class="side-nav-link-ref"
                                role="link"
                                @click="navigate"
                                >{{ resumeUserLabel }}</a
                            >
                        </router-link>
                    </li>
                    <li v-if="$can('GET', 'AUDITSEXPORTSCREEN')">
                        <router-link
                            v-slot="{ navigate }"
                            to="/exportJobs"
                            custom
                            ><a
                                class="side-nav-link-ref"
                                role="link"
                                @click="navigate"
                                >{{ exportJobsLabel }}</a
                            >
                        </router-link>
                    </li>
                    <li v-if="$can('GET', 'IMPORTSSCREEN') || costOfServingModuleEnable">
                        <router-link
                            v-slot="{ navigate }"
                            to="/importJobs"
                            custom
                            ><a
                                class="side-nav-link-ref"
                                role="link"
                                @click="navigate"
                                >{{ importJobLabel }}</a
                            >
                        </router-link>
                    </li>
                    <li v-if="$can('GET', 'EXPORTSSCREEN')">
                        <router-link
                            v-slot="{ navigate }"
                            to="/exportPresentationJobs"
                            custom
                            ><a
                                class="side-nav-link-ref"
                                role="link"
                                @click="navigate"
                                >{{ exportPresentationJobLabel }}</a
                            >
                        </router-link>
                    </li>
                    <li v-if="$can('GET', 'AUDITSEXPORTSCREEN')">
                        <router-link
                            v-slot="{ navigate }"
                            to="/operationalProblem"
                            custom
                            ><a
                                class="side-nav-link-ref"
                                role="link"
                                @click="navigate"
                                >{{ operationalProblem }}</a
                            >
                        </router-link>
                    </li>
                </ul>
            </li>
        </ul>
        <div class="clearfix"></div>
    </div>
    <!-- End Sidebar -->
</template>

<style lang="scss">
@import '~metismenujs/scss/metismenujs';
</style>
