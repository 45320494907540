<script>
export default {
    computed: {
        i18nDevelopedBy() {
            return this.getI18n('COMMON', 'developed_by')
        },
        i18nAnd() {
            return this.getI18n('COMMON', 'and').toLowerCase()
        },
    },
}
</script>

<template>
    <footer class="footer">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12 text-center">
                    ©
                    {{ new Date().getFullYear() }}
                    - {{ i18nDevelopedBy }} <b>PDV Mundo</b> {{ i18nAnd }} <b>Webbing Brasil</b>.
                </div>
            </div>
        </div>
    </footer>
</template>
